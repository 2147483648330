#multicurrency,
#transactions,
#analytics,
#budget,
#recurring,
#security,
#compare {
  .feature-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3em;

    p {
      font-size: 1.25rem;
      text-align: center;
      max-width: 700px;
      padding: 0 1em;
      margin: 1.5em 0;
    }
    h3 {
      text-align: center;
    }
    div.feature-grid-square {
      padding: 0 2em;
    }
    img.screenshot {
      width: 90%;
      margin: 0em auto;
      padding: 2em 0;
      max-width: 75%;
      padding-right: 0;

      @media (max-width: 600px) {
        max-width: 95%;
        margin: 0 auto;
      }
    }

    .testimonials {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    .testimonial {
      position: relative;
      width: 25%;
      text-align: left;
      padding: 1.7em 1.3em 1.3em 1.3em;
      background-color: #fff;
      border: 1px solid #ececec;
      color: #343434;
      border-radius: 5px;
      margin: 2em 0.5em;
      line-height: 1.5em;
      height: 100%;

      &.wide {
        font-size: 1.1em;
        width: 80%;
        max-width: 600px;
        margin-bottom: 1em;
      }

      @media (min-width: 800px) and (max-width: 1040px) {
        .hide-on-mobile {
          display: none;
        }
      }

      @media (min-width: 800px) and (max-width: 900px) {
        font-size: 15px;
        padding: 1.6em 1em 1em 1em;
      }
      @media (max-width: 800px) {
        max-width: 600px;
        font-size: 1.1rem;
        padding: 1.6em 1em 1em 1em;
        margin: 1em 0.5em;
        width: 85%;
      }

      p {
        font-size: 1em;
        max-width: 100%;
        padding: 0;
        margin: 0 0 0.5em 0;
      }

      .user {
        margin-top: 2.2em;
        display: block;
        text-align: center;
        font-size: 0.75em;
        line-height: 0.5em;
        font-weight: bold;
        color: #bababa;
        text-transform: uppercase;
      }

      .start-quote {
        position: absolute;
        right: calc(50% - 15px);
        top: -16px;
        border: 3px solid #fbb700;
        background-color: #fbb700;
        background-image: url('/assets/images/start-quote-white.svg');
        background-size: 14px 14px;
        background-position: center;
        height: 25px;
        width: 25px;
        border-radius: 15px;
        background-repeat: no-repeat;
        margin-right: 2px;
      }
    }
  }

  #grouped .feature-content img {
    max-width: 600px;
    width: 95%;
  }
}
